import whitelabelKeys from '../../global-config-variables/whitelabel-keys';

export const WHITELABEL_KEYS = whitelabelKeys;

export const WHITE_LABELS = {
  [WHITELABEL_KEYS.xooply]: {
    key: 'xooply',
    label: 'Xooply',
  },
  [WHITELABEL_KEYS.callpro]: {
    key: 'callpro',
    label: 'Callpro',
  },
  [WHITELABEL_KEYS.prakerja]: {
    key: 'prakerja',
    label: 'Prakerja',
  },
  [WHITELABEL_KEYS.warungMekaar]: {
    key: 'warung_mekaar',
    label: 'Warung Mekaar',
  },
  [WHITELABEL_KEYS.idfood]: {
    key: 'idfood',
    label: 'ID Food',
  },
  [WHITELABEL_KEYS.madaniMart]: {
    key: 'madani_mart',
    label: 'Madani Mart',
  },
};
