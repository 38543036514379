import request from 'utils/request.utils';
import { parseCookies } from 'nookies';

export const getNotificationList = async () => {
    const cookies = parseCookies();
    try {
        const response = await request({
            withAuth: true,
            tokenAuth: cookies.token,
        }).get(`/notification`);
        if (response.status === 200) {
            return response.data.data.data;
        } else {
            throw response;
        }
    } catch (e) {
        throw e.message;
    }
};

export const getNotificationListPage = async (params) => {
    const cookies = parseCookies();
    try {
        const response = await request({
            withAuth: true,
            tokenAuth: cookies.token,
        }).get(`/notification`, {params});
        if (response.status === 200) {
            return response.data;
        } else {
            throw response;
        }
    } catch (e) {
        throw e.message;
    }
};

export const getNotificationCount = async () => {
    const cookies = parseCookies();
    try {
        const response = await request({
            withAuth: true,
            tokenAuth: cookies.token,
        }).get(`/notification/unread`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw response;
        }
    } catch (e) {
        throw e.message;
    }
};

export const getNotificationById = async (id) => {
    const cookies = parseCookies();
    try {
        const response = await request({
            withAuth: true,
            tokenAuth: cookies.token,
        }).get(`/notification/${id}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw response;
        }
    } catch (e) {
        throw e.message;
    }
};

export const readNotificationById = async (id) => {
    const cookies = parseCookies();
    try {
        const response = await request({
            withAuth: true,
            tokenAuth: cookies.token,
        }).put(`/notification/read?uid=${id}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw response;
        }
    } catch (e) {
        throw e.message;
    }
};

export const readAllNotifications = async () => {
    const cookies = parseCookies();
    try {
        const response = await request({
            withAuth: true,
            tokenAuth: cookies.token,
        }).put(`/notification/read`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw response;
        }
    } catch (e) {
        throw e.message;
    }
};
