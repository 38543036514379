import { USER_ROLE } from 'constants/index';
import { parseCookies } from 'nookies';
import {
  convertCartItemToFirstPurchase,
  convertProductDetailToFirstPurchase,
  isProductNameIsFirstPurchase,
} from 'utils/first-purchase';
import request, { apiRequest, API_PREFIX } from 'utils/request.utils';
import { constructErrorData } from 'utils/response.utils';

const BASE_CART_URL = '/buyer/cart';
const BASE_CART_TRANSACTION_URL = '/buyer/order';
const BASE_BUDGET_COMPANY_URL = '/buyer/budget-company';
const BASE_RFQ_URL = '/buyer/quotation-request';

export const getCart = async () => {
  try {
    const response = await request({ withAuth: true }).get(BASE_CART_URL);
    if (response.status === 200) {
      const responseData = response.data;
      const cartItems = [...(responseData.data.cartItems || [])];
      const convertedCartItems = cartItems.map((item) => {
        if (isProductNameIsFirstPurchase(item.productTitle)) {
          return convertCartItemToFirstPurchase(item);
        }
        return item;
      });
      responseData.data.cartItems = convertedCartItems;
      return responseData;
    }
    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};

export const getCartSnapshot = async () => {
  try {
    const response = await request({ withAuth: true }).get(`${BASE_CART_URL}/snapshot`);
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};

export const getCountCart = async () => {
  try {
    const response = await request({ withAuth: true }).get(BASE_CART_URL);
    if (response.status === 200) {
      const data = response?.data?.data?.cartItems?.length || 0;
      return {
        data,
      };
    }
    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};
export const getSnapshotCart = async () => {
  try {
    const response = await request({ withAuth: true }).get(`${BASE_CART_URL}/snapshot`);
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};

export const upsertItemCart = async (data) => {
  try {
    const response = await request({ withAuth: true }).post(BASE_CART_URL, data);
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    // throw constructErrorData(e).message ?? e.message;
    throw e.response.data;
  }
};

export const recalculateCart = async (data) => {
  try {
    const response = await request({ withAuth: true }).post(`${BASE_CART_URL}/calculate`, data);
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};

export const removeItemCart = async (data) => {
  try {
    const response = await request({ withAuth: true }).delete(`${BASE_CART_URL}?${data}`);
    if (response.status === 200) {
      return response?.data?.data;
    }

    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};

export const transactionOrderReview = async (data) => {
  try {
    const cookies = parseCookies();

    if (cookies.role === USER_ROLE.CUSTOMER) {
      const response = await apiRequest({ prefix: API_PREFIX.v2 })({ withAuth: true }).post(
        '/order/review',
        { ...data, isMeteraiPurchase: !!data?.isMeteraiPurchase },
      );
      if (response.status === 200) {
        const responseData = response.data;
        const convertedOrderReviews = [...(responseData.data.orderReviews || [])].map(
          (orderReview) => ({
            ...orderReview,
            productReviewList: orderReview.productReviewList.map((item) => {
              if (isProductNameIsFirstPurchase(item.productName)) {
                return convertProductDetailToFirstPurchase(item);
              }
              return item;
            }),
          }),
        );
        responseData.data.orderReviews = convertedOrderReviews;
        return responseData;
        // return response.data;
      }
      throw response;
    }

    const response = await request({ withAuth: true }).post(
      `${BASE_CART_TRANSACTION_URL}/review`,
      data,
    );
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    // throw constructErrorData(e).message ?? e.message;
    throw e?.response?.data;
  }
};

export const transactionOrderReviewDocument = async (data) => {
  try {
    const response = await request({ withAuth: true }).post(
      `${BASE_CART_TRANSACTION_URL}/review/document`,
      data,
    );
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};

export const transactionOrderRecalculated = async (data) => {
  try {
    const response = await request({ withAuth: true }).post(
      `${BASE_CART_TRANSACTION_URL}/review/calculate`,
      data,
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};

export const getBudgetCompany = async () => {
  try {
    const response = await request({ withAuth: true }).get(
      `${BASE_BUDGET_COMPANY_URL}/budget-info-staff-company`,
    );
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};

export const transactionOrderReviewCustomer = async (data) => {
  try {
    const response = await request({ withAuth: true }).post('/order/review', data);
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};

export const addQuotationPdp = async (data) => {
  try {
    const response = await request({ withAuth: true }).post(`${BASE_RFQ_URL}`, data);
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};

export const postFinishQuotation = async ({ id, dataForm }) => {
  try {
    const response = await request({ withAuth: true }).post(
      `${BASE_RFQ_URL}/${id}/finish`,
      dataForm,
    );
    if (response.status === 200) {
      return response.data;
    }
    throw response;
  } catch (e) {
    throw constructErrorData(e).message ?? e.message;
  }
};
