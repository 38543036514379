const MAGIC_WORD = 'first-purchase';

export const isProductNameIsFirstPurchase = (productName) => {
  return String(productName).indexOf(MAGIC_WORD) > -1;
};

export const isProductFirstPurchase = (product) => {
  const { title } = product;

  if (isProductNameIsFirstPurchase(title)) return true;

  return false;
};

export const convertProductDetailToFirstPurchase = (productDetail) => {
  // const { finalPrice, price, priceWithOrPpn } = productDetail;
  return {
    ...productDetail,
    finalPrice: 0,
    price: 0,
    priceWithOrPpn: 0,
    subTotalPrice: 0,
    wholeSalePrice: 0,
    isFirstPurchase: true,
  };
};

export const convertCartItemToFirstPurchase = (productCartItem) => {
  // const { price, subTotalPrice, totalPrice } = productCartItem;
  return {
    ...productCartItem,
    price: 0,
    subTotalPrice: 0,
    totalPrice: 0,
    isFirstPurchase: true,
  };
};

export const convertSkuProductToFirstPurchase = (skuDetail) => {
  // const { price } = skuDetail;
  return {
    ...skuDetail,
    price: 0,
    isFirstPurchase: true,
  };
};
