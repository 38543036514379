import { useEffect } from 'react';
import { useState } from 'react';

const useGetHeaderHeight = () => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const headerElement = window.document.querySelector('header');
      if (headerElement) {
        setValue(headerElement.offsetHeight || 0);
      }
    }
  }, []);

  return value;
};

export default useGetHeaderHeight;
