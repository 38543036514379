const rebuildErrorData = (data) => {
  try {
    if (data.message && data.message.includes('"fieldError"')) {
      data.fieldError = JSON.parse(data.message).fieldError;
      data.message = "Please check your inputed value again.";
    }
    return data;
  } catch (e) {
    return data;
  }
};

export const constructErrorData = (error) => {
  if (!error) {
    return error;
  }

  if (!error.response) {
    return error;
  }

  var data = error.response.data;

  if (!data) {
    return error;
  }

  return rebuildErrorData(data);
};

export const extractFieldErrorName = (originalFieldName) => {
  if (originalFieldName) {
    const fieldNames = originalFieldName.split(".");
    return fieldNames[fieldNames.length - 1];
  }
  return originalFieldName;
};
